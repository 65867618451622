import React from "react";
import styled from "styled-components";
import { ICareerPath } from "../../store/careerPaths";
import Image from "../../ui/components/Image";
import Text from "../../ui/components/Text";
import Button from "../../ui/components/Button";
import { useNavigate } from "react-router-dom";
import icons from "../../ui/icons";
import RightPanel from "./right-panel";
import { motion } from "framer-motion";
import { routeNames } from "../../router/config";

const SCareerDetails = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    gap: 41px;
    padding: 109px 95px;

    ${(p) => p.theme.mobileQuery} {
        padding: 16px;
        gap: 16px;
    }
`;

const SContent = styled.div`
    display: flex;
    gap: 81px;

    ${(p) => p.theme.tabletQuery} {
        flex-direction: column;
        gap: 30px;
        padding-bottom: 80px;
    }

    ${(p) => p.theme.mobileQuery} {
        flex-direction: column;
        gap: 30px;
        padding-bottom: 80px;
    }
`;

const SImageContainer = styled(motion.div)`
    position: relative;
    aspect-ratio: 1 / 1;
    height: 688px;

    ${(p) => p.theme.mobileQuery} {
        height: 100vw;
    }

    cursor: pointer;
    transition: 0.3s;
    :hover {
        filter: brightness(1.1);
    }
`;

const SImage = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;

    ${(p) => p.theme.mobileQuery} {
        left: -16px;
        width: 100vw;
        height: auto;
        aspect-ratio: 1 / 1;
    }
`;

const SGradient = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 36.46%,
        rgba(0, 0, 0, 0.8) 82.35%
    );
    padding: 48px;
    display: flex;
    flex-direction: column-reverse;

    ${(p) => p.theme.mobileQuery} {
        padding: 24px;
    }
`;

interface IProps {
    career: ICareerPath;
}

const View: React.FC<IProps> = (props) => {
    const navigate = useNavigate();

    return (
        <SCareerDetails>
            <Button
                icon={icons.backArrow}
                onClick={() => navigate(routeNames.careers)}
            >
                Back to Careers
            </Button>
            <SContent>
                <SImageContainer
                    onClick={() => window.open(props.career.url)}
                    whileTap={{ scale: 0.99 }}
                    whileHover={{ scale: 1.01 }}
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                >
                    <SImage>
                        <SGradient>
                            <Text size="H1" color="white">
                                {props.career.name}
                            </Text>
                        </SGradient>
                        <Image image={props.career.image} imagePosition="top" />
                    </SImage>
                </SImageContainer>

                <RightPanel career={props.career} />
            </SContent>
        </SCareerDetails>
    );
};

export default View;
