import React, { useMemo, useEffect } from "react";
import View from "./view";
import { ICareerPath } from "../../../store/careerPaths";
import useUserSelectionStore, {
    onCareerSelected,
} from "../../../store/userSelection";
import { useNavigate } from "react-router-dom";
import { params, routeNames } from "../../../router/config";

interface IProps extends React.PropsWithChildren {
    career: ICareerPath;
    deactivated?: boolean;
}

const CareerPathCard: React.FC<IProps> = (props) => {
    const [selectedFilters, setCareerPathState] = useUserSelectionStore(
        (state) => [state.selectedFilters, state.setCareerPathState]
    );
    const navigate = useNavigate();

    const active = useMemo(() => {
        // If there are no selected filters, show all cards.
        if (Object.keys(selectedFilters).length === 0) return true;
        // If this card has no added filters, deactivate it.
        if (!props.career.filters) return false;

        let state = true;

        // Foreach selected filter, check if this card has it, if not, deactivate it.
        Object.keys(selectedFilters).forEach((filterId) => {
            if (!(filterId in props.career.filters)) state = false;
        });

        return state;
    }, [selectedFilters]);

    useEffect(() => setCareerPathState(props.career, active), [active]);

    function clickHandler() {
        if (!active) return;
        onCareerSelected.trigger({ career: props.career });
        navigate(
            `${routeNames.careerDetails}?${params.career}=${props.career.id}`
        );
    }

    return (
        <View
            {...props}
            active={active && !props.deactivated}
            onClick={() => clickHandler()}
        />
    );
};

export default CareerPathCard;
