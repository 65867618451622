import { create } from "zustand";
import { Dictionary, IBase } from ".";

export interface IFilter extends IBase {}

interface IFilterState {
    database: string;
    filters: Dictionary<IFilter>;
    loading: boolean;
    setFilters: (filters: Dictionary<IFilter>) => void;
}

const useFiltersStore = create<IFilterState>()((set) => ({
    database: "filter",
    filters: {},
    loading: true,
    setFilters: (filters) => set(() => ({ filters, loading: false })),
}));

export default useFiltersStore;
