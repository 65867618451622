import React from "react";
import styled from "styled-components";
import Logo from "../../components/Logo";
import CareerPathCatalog from "./career-path-catealog";
import FilterSection from "./filter-section";
import Loading from "../../components/Loading";

const SCareers = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0 95px;
    justify-content: center;

    ${(p) => p.theme.tabletQuery} {
        padding: 77px 72px;
        height: auto;
        min-height: 100vh;
    }

    ${(p) => p.theme.mobileQuery} {
        padding: 24px 8px;
        gap: 12px;
        height: auto;
        min-height: 100vh;
    }
`;

const SContent = styled.div`
    display: flex;
    gap: 81px;

    ${(p) => p.theme.tabletQuery} {
        flex-direction: column;
        gap: 33px;
    }

    ${(p) => p.theme.mobileQuery} {
        flex-direction: column;
        gap: 33px;
    }
`;

const View: React.FC<{ loading: boolean }> = (props) => {
    return (
        <SCareers>
            <Logo big={props.loading} />
            {!props.loading ? (
                <SContent>
                    <CareerPathCatalog />
                    <FilterSection />
                </SContent>
            ) : (
                <Loading />
            )}
        </SCareers>
    );
};

export default View;
