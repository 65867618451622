import { create } from "zustand";
import { Dictionary, IBase } from ".";
import { IFilter } from "./filters";
import { IThemeColors } from "../ui/theme";

export interface ICareerPath extends IBase {
    image: string;
    smallImage: string;
    icon: string;
    url: string;
    colors: {
        background: keyof IThemeColors;
        text: keyof IThemeColors;
    };
    filters: Dictionary<number>;
    callToAction?: string;
    description?: string;
}

interface ICareerPathState {
    database: string;
    careerPaths: Dictionary<ICareerPath>;
    loading: boolean;
    setCareerPaths: (careerPaths: Dictionary<ICareerPath>) => void;
}

const useCareerPathStore = create<ICareerPathState>()((set) => ({
    database: "career-path",
    careerPaths: {},
    loading: true,
    setCareerPaths: (careerPaths) =>
        set(() => ({ careerPaths, loading: false })),
}));

export default useCareerPathStore;
