import { create } from "zustand";
import { Dictionary, IBase } from ".";
import { IThemeColors } from "../ui/theme";

export interface ICategory extends IBase {
    filters: Dictionary<string>;
    color: keyof IThemeColors;
}

interface ICategoryState {
    database: string;
    categories: Dictionary<ICategory>;
    loading: boolean;
    setCategories: (category: Dictionary<ICategory>) => void;
}

const useCategoriesStore = create<ICategoryState>()((set) => ({
    database: "category",
    categories: {},
    loading: true,
    setCategories: (categories) => set(() => ({ categories, loading: false })),
}));

export default useCategoriesStore;
