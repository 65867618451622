import { FC } from "react";
import { IThemeColors, IThemeFontSizes } from "../theme";
import styled, { css, CSSProperties } from "styled-components";
import { motion, MotionProps } from "framer-motion";

interface ITextProps extends MotionProps {
    size?: keyof IThemeFontSizes;
    color?: keyof IThemeColors;
    oneline?: boolean;
    selected?: boolean;
    onClick?(): void;
    noBorder?: boolean;
    center?: boolean;
    middle?: boolean;
    style?: CSSProperties;
    font?: "poppins" | "walsheim";
    lineHeight?: string;
    customSize?: string;
}

const SText = styled(motion.div)<ITextProps>`
    // Size.
    font-size: ${(p) => p.customSize ?? p.theme.fontSize[p.size ?? "H5"]};

    // One line.
    white-space: ${(p) => (p.oneline ? "nowrap" : "pre-wrap")};

    // Center.
    ${(p) =>
        p.center &&
        css`
            text-align: center;
        `}

    // Middle.
    ${(p) =>
        p.middle &&
        css`
            margin: auto;
        `}

    // Color.
    ${(p) =>
        p.color &&
        css`
            color: ${p.theme.colors[p.color]};
        `}

    // Font.
    ${(p) =>
        p.font &&
        css`
            font-family: ${p.font};
        `}

    // Interactable.
    ${(p) =>
        p.onClick &&
        css`
            cursor: pointer;
            :hover {
                filter: brightness(1.2);
            }
        `}
    
    // Is Selected.
    ${(p) =>
        p.selected &&
        css`
            color: ${(p) => p.theme.colors.deepGreen};
        `}
    
    line-height: ${(p) => p.lineHeight ?? "100%"};

    a {
        color: ${(p) => p.theme.colors.deepGreen};
    }
`;

const Text: FC<ITextProps> = (props) => {
    return <SText {...props}>{props.children}</SText>;
};

export default Text;
