import { FC, PropsWithChildren } from "react";
import styled, { css, CSSProperties } from "styled-components";
import { motion } from "framer-motion";
import Text from "./Text";
import SVG from "./SVG";

interface IButtonProps extends PropsWithChildren {
    onClick?(): void;
    style?: CSSProperties;
    icon?: string;
    iconRight?: boolean;
}

const SButton = styled.div<IButtonProps>`
    all: unset;
    padding: 15px;
    cursor: pointer;
    width: min-content;

    transition: 0.3s;
    :hover {
        transform: scale(1.1);
    }

    :active {
        transform: scale(0.9);
    }

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    background-color: ${(p) => p.theme.colors.deepGreen};

    ${(p) =>
        p.icon &&
        css`
            display: grid;
            grid-template-columns: 16px auto;

            ${p.iconRight &&
            css`
                grid-template-columns: auto 16px;
            `}
            grid-gap: 16px;
        `}
`;

const Button: FC<IButtonProps> = (props) => {
    return (
        <SButton {...props}>
            {props.icon && !props.iconRight && (
                <SVG color="white" image={props.icon} contain />
            )}
            <Text size="H5" color="white" oneline>
                {props.children}
            </Text>
            {props.icon && props.iconRight && (
                <SVG color="white" image={props.icon} contain />
            )}
        </SButton>
    );
};

export default Button;
