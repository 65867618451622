import React, { useMemo } from "react";
import View from "./view";
import { IFilter } from "../../../store/filters";
import useUserSelectionStore, {
    onFilterSelected,
} from "../../../store/userSelection";
import { ICategory } from "../../../store/categories";

interface IProps extends React.PropsWithChildren {
    filter: IFilter;
    category: ICategory;
}

const Filter: React.FC<IProps> = (props) => {
    const [toggleFilter, selectedFilters, activeCareerPaths] =
        useUserSelectionStore((state) => [
            state.toggleFilter,
            state.selectedFilters,
            state.activeCareerPaths,
        ]);

    const active = useMemo(() => {
        return props.filter.id in selectedFilters;
    }, [selectedFilters]);

    const disabled = useMemo(() => {
        let disabled = true;
        Object.values(activeCareerPaths).forEach((career) => {
            if (props.filter.id in (career.filters ?? {})) disabled = false;
        });

        return disabled;
    }, [activeCareerPaths]);

    const clickHandler = () => {
        onFilterSelected.trigger({
            filter: props.filter,
            category: props.category,
            selected: !active,
        });

        toggleFilter(props.filter);
    };

    return (
        <View
            label={props.filter.name}
            active={active}
            disabled={disabled}
            onClick={() => clickHandler()}
        />
    );
};

export default Filter;
