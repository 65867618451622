import React from "react";
import styled from "styled-components";
import Title from "../../../components/Title";
import { Dictionary } from "../../../store";
import Text from "../../../ui/components/Text";
import { IThemeColors } from "../../../ui/theme";
import { SDot, SDots } from "../../../components/Dots";

const SCategory = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const SList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 16px;
`;

const SFilter = styled.div`
    display: flex;
    flex-grow: 1;
`;

const vDots = {
    visible: {
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.05,
        },
    },
};

const vDot = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
    },
};

const View: React.FC<{
    title: string;
    filters: Dictionary<{ name: string; amount: number }>;
    color: keyof IThemeColors;
}> = (props) => {
    return (
        <SCategory>
            <Title>{props.title}</Title>
            <SList>
                {Object.values(props.filters).map((filter) => (
                    <SFilter key={filter.name}>
                        <Text
                            style={{ flexGrow: 1 }}
                            key={filter.name}
                            size="H4"
                            color="warmGrey"
                            oneline
                        >
                            {filter.name}
                        </Text>
                        <SDots
                            animate="visible"
                            initial="hidden"
                            variants={vDots}
                        >
                            {new Array(10).fill(0).map((value, i) => (
                                <SDot
                                    variants={vDot}
                                    key={i}
                                    active={i < filter.amount}
                                    color={props.color}
                                />
                            ))}
                        </SDots>
                    </SFilter>
                ))}
            </SList>
        </SCategory>
    );
};

export default View;
