import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import { ICategory } from "../../../store/categories";
import { wrap } from "popmotion";
import FilterCategory from "../filter-category";

const SSlider = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;
`;

const SContentHolder = styled(motion.div)<{ rows: number }>`
    position: relative;
    height: min-content;
    min-height: calc(79px + (40px * ${(p) => p.rows}));
`;

const SContent = styled(motion.div)`
    position: absolute;
    width: 100%;
`;

const SDots = styled(motion.div)`
    display: flex;
    gap: 4px;
    width: min-content;
    margin: auto;
`;

const SDot = styled(motion.div)<{ active: boolean }>`
    width: 8px;
    height: 8px;
    border-radius: 50%;

    background-color: ${(p) =>
        p.active ? p.theme.colors.teal : p.theme.colors.warmGrey};
`;

const variants = {
    enter: (direction: number) => {
        return {
            x: direction > 0 ? 500 : -500,
            opacity: 0,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 500 : -500,
            opacity: 0,
        };
    },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
};

const Slider: React.FC<{ categories: ICategory[] }> = (props) => {
    const [[page, direction], setPage] = useState([0, 0]);

    const pageIndex = wrap(0, props.categories.length, page);

    const paginate = (newDirection: number) => {
        setPage([page + newDirection, newDirection]);
    };

    const rows = Math.ceil(
        Object.keys(props.categories?.[pageIndex]?.filters ?? {}).length / 4
    );

    return (
        <SSlider>
            <AnimatePresence initial={false} custom={direction}>
                <SContentHolder rows={rows}>
                    <SContent
                        key={page}
                        custom={direction}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{
                            x: { type: "spring", stiffness: 300, damping: 30 },
                            opacity: { duration: 0.5 },
                        }}
                        drag="x"
                        dragConstraints={{ left: 0, right: 0 }}
                        dragElastic={1}
                        onDragEnd={(e, { offset, velocity }) => {
                            const swipe = swipePower(offset.x, velocity.x);

                            if (swipe < -swipeConfidenceThreshold) {
                                paginate(1);
                            } else if (swipe > swipeConfidenceThreshold) {
                                paginate(-1);
                            }
                        }}
                    >
                        <FilterCategory
                            category={props.categories[pageIndex]}
                        />
                    </SContent>
                </SContentHolder>
            </AnimatePresence>
            <SDots>
                {props.categories.map((c, i) => (
                    <SDot key={c.id} active={pageIndex === i} />
                ))}
            </SDots>
        </SSlider>
    );
};

export default Slider;
