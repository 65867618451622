import React from "react";
import styled, { css } from "styled-components";
import { ICareerPath } from "../../../store/careerPaths";
import Text from "../../../ui/components/Text";
import Image from "../../../ui/components/Image";
import SVG from "../../../ui/components/SVG";

const SContainer = styled.div<{ active?: boolean; career: ICareerPath }>`
    padding: 16px;
    width: 160px;

    aspect-ratio: 1 / 1;

    background-color: ${(p) => p.theme.colors[p.career?.colors?.background]};
    transition: 0.3s;

    position: relative;
    display: flex;
    flex-direction: column-reverse;

    ${(p) =>
        p.active &&
        css`
            cursor: pointer;
            transform: rotateY(180deg) scaleX(-1);

            :hover {
                transform: rotateY(180deg) scaleX(-1) scale(1.1);
            }
        `}

    ${(p) => p.theme.tabletQuery} {
        width: auto;
    }

    ${(p) => p.theme.mobileQuery} {
        padding: 8px;
        width: auto;
    }
`;

const SImage = styled.div<{ active?: boolean }>`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    transition: 0.3s;
    opacity: ${(p) => (p.active ? 1 : 0)};
`;

const SGradient = styled.div`
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 36.46%,
        rgba(0, 0, 0, 0.7) 84.9%
    );
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;

const SIcon = styled.div`
    position: absolute;
    z-index: 1;

    top: 50%;
    left: 50%;
    width: 35%;
    height: 35%;
    transform: translate(-50%, -50%);
`;

interface IProps {
    career: ICareerPath;
    active?: boolean;
    onClick?: () => void;
}

const View: React.FC<IProps> = (props) => {
    return (
        <SContainer {...props}>
            <SImage active={props.active}>
                <SGradient />
                <Image image={props.career.smallImage} imagePosition="top" />
            </SImage>
            {props.active ? (
                <Text size={"H4"} color={"white"} style={{ zIndex: 1 }}>
                    {props.career.name}
                </Text>
            ) : (
                <SIcon>
                    <Image image={props.career.icon} contain />
                </SIcon>
            )}
        </SContainer>
    );
};

export default View;
