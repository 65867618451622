import React from "react";
import styled from "styled-components";
import { ICareerPath } from "../../../store/careerPaths";
import { Dictionary } from "../../../store";
import Category from "../category";
import { IThemeColors } from "../../../ui/theme";
import Button from "../../../ui/components/Button";
import icons from "../../../ui/icons";
import { motion } from "framer-motion";
import { getSorted } from "../../../utils";
import Text from "../../../ui/components/Text";
import { onVisitMyWOrkplaceClicked } from "../../../store/userSelection";

const SRightPanel = styled(motion.div)`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 48px;
    height: min-content;
`;

const View: React.FC<{
    career: ICareerPath;
    callToAction: string;
    categories: Dictionary<{
        id: string;
        name: string;
        color: keyof IThemeColors;
        order?: number;
        filters: Dictionary<number>;
    }>;
    description?: string;
}> = (props) => {
    const callToActionHandler = () => {
        onVisitMyWOrkplaceClicked.trigger({ career: props.career });
        window.open(props.career.url);
    };

    return (
        <SRightPanel
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
        >
            {Object.values(props.categories)
                .sort(getSorted)
                .map((category) => (
                    <Category key={category.id} category={category} />
                ))}
            <Button
                onClick={() => callToActionHandler()}
                icon={icons.frontArrow}
                iconRight
                style={{ margin: "auto" }}
            >
                {props.callToAction}
            </Button>
        </SRightPanel>
    );
};

export default View;
