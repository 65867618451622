import React from "react";
import styled from "styled-components";
import { Dictionary } from "../../../store";
import { ICareerPath } from "../../../store/careerPaths";
import CareerPathCard from "../career-path-card";
import { motion } from "framer-motion";
import Text from "../../../ui/components/Text";

const SCareerPathCatalog = styled(motion.div)`
    display: flex;
    flex-direction: column;
    gap: 8px;
    ${(p) => p.theme.desktopQuery} {
        max-width: 680px;
    }
`;

const SGrid = styled(motion.div)`
    display: grid;
    grid-template-columns: repeat(4, min-content);
    gap: 16px;

    ${(p) => p.theme.tabletQuery} {
        grid-template-columns: repeat(4, 1fr);
    }

    ${(p) => p.theme.mobileQuery} {
        gap: 8px;
        grid-template-columns: repeat(4, 1fr);
    }
`;

const View: React.FC<{
    items: Dictionary<{ career: ICareerPath; deactivated?: boolean }>;
    description?: string;
}> = (props) => {
    return (
        <SCareerPathCatalog
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
        >
            <SGrid>
                {Object.values(props.items).map((item) => (
                    <CareerPathCard
                        key={item.career.id}
                        career={item.career}
                        deactivated={item.deactivated}
                    />
                ))}
            </SGrid>
            <Text size="H4" color="warmGrey" lineHeight="15px">
                {props.description ?? ""}
            </Text>
        </SCareerPathCatalog>
    );
};

export default View;
