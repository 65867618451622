interface ISortItem {
    order?: number;
}
export function getSorted(itemA: ISortItem, itemB: ISortItem): 1 | -1 {
    const a = itemA.order ?? Infinity;
    const b = itemB.order ?? Infinity;

    return a > b ? 1 : -1;
}

export class Action<T> {
    private handlers: Array<(data: T) => void> = [];

    public on(handler: (data: T) => void): void {
        this.handlers.push(handler);
    }

    public off(handler: (data: T) => void): void {
        this.handlers = this.handlers.filter((h) => h !== handler);
    }

    public removeAllListeners(): void {
        this.handlers = [];
    }

    public trigger(data: T): void {
        // Duplicate the array to avoid side effects during iteration.
        this.handlers.slice(0).forEach((h) => h(data));
    }
}
