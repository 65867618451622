import { FC, ReactNode } from "react";
import styled, { CSSProperties } from "styled-components";
import { MotionProps, motion } from "framer-motion";

const SImageContainer = styled(motion.div)<IImageProps>`
    height: 100%;
    border-radius: ${(p) => (p.round ? "10px" : 0)};
    overflow: hidden;
`;

const SImage = styled(motion.div)<IImageProps>`
    background-image: url(${(p) => p.image}), url(${(p) => p.placeholder});
    background-size: ${(p) =>
        p.contain ? "contain" : p.fit ? "100% 100%" : "cover"};
    background-repeat: no-repeat;
    background-position: ${(p) => p.imagePosition ?? "center"};
    height: 100%;
`;

interface IImageProps extends MotionProps {
    url?: string;
    image?: string;
    placeholder?: string;
    contain?: boolean;
    fit?: boolean;
    round?: boolean;
    style?: CSSProperties;
    imagePosition?: string;
    children?: ReactNode;
}

const Image: FC<IImageProps> = (props) => {
    return (
        <SImageContainer {...props}>
            <SImage {...props}>{props.children}</SImage>
        </SImageContainer>
    );
};

export default Image;
