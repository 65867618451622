import { create } from "zustand";
import { IBase } from ".";

export interface IGlobal extends IBase {
    texts: {
        callToAction: string;
        description: string;
    };
    infoLink?: {
        buttonText?: string;
        link?: string;
    };
}

interface IGlobalState {
    database: string;
    global?: IGlobal;
    loading: boolean;
    setGlobal: (global: IGlobal) => void;
}

const useGlobalStore = create<IGlobalState>()((set) => ({
    database: "global",
    loading: true,
    setGlobal: (global) => set(() => ({ global, loading: false })),
}));

export default useGlobalStore;
