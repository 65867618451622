import React, { useMemo } from "react";
import styled from "styled-components";
import Text from "../ui/components/Text";
import Button from "../ui/components/Button";
import icons from "../ui/icons";
import useGlobalStore from "../store/global";
import { onInfoButtonClicked } from "../store/userSelection";

const SLogo = styled.div`
    position: relative;
`;
const SRow = styled.div`
    display: flex;
`;

const SInfoButton = styled.div`
    position: absolute;
    height: min-content;
    width: min-content;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
`;

const Logo: React.FC<{ big?: boolean }> = (props) => {
    const customSize = props.big ? "40px" : "24px";
    const infoLink = useGlobalStore((state) => state.global?.infoLink);

    const showInfoButton = useMemo(() => {
        return !props.big && infoLink?.buttonText && infoLink?.link;
    }, [infoLink, props.big]);

    const infoButtonHandler = () => {
        onInfoButtonClicked.trigger(null);
        window.open(infoLink?.link);
    };

    return (
        <SLogo>
            <Text customSize={customSize} color="black" font="walsheim">
                Careers in
            </Text>
            <Text customSize={customSize} color="deepGreen" font="walsheim">
                viticulture
            </Text>
            <SRow>
                <Text customSize={customSize} color="black" font="walsheim">
                    and{" "}
                </Text>
                <Text customSize={customSize} color="magenta" font="walsheim">
                    wine
                </Text>
            </SRow>
            {showInfoButton && (
                <SInfoButton>
                    <Button
                        icon={icons.frontArrow}
                        iconRight
                        onClick={() => infoButtonHandler()}
                    >
                        Info
                    </Button>
                </SInfoButton>
            )}
        </SLogo>
    );
};

export default Logo;
