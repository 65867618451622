import React, { useState, useEffect, PropsWithChildren } from "react";

// Theme.
import { ThemeProvider } from "styled-components";
import Theme, { MobileTheme, TabletTheme } from "./theme";
import { useIsMobile, useIsTablet } from "../utils/hooks";

const ThemeContainer: React.FC<PropsWithChildren> = ({ children }) => {
    const [theme, setTheme] = useState(Theme);
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();

    useEffect(() => {
        setTheme(isMobile ? MobileTheme : isTablet ? TabletTheme : Theme);
    }, [isMobile, isTablet]);

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeContainer;
