import React, { useMemo } from "react";
import View from "./view";
import { Dictionary } from "../../../store";
import useFiltersStore from "../../../store/filters";
import { IThemeColors } from "../../../ui/theme";

const Category: React.FC<{
    category: {
        name: string;
        filters: Dictionary<number>;
        color: keyof IThemeColors;
    };
}> = (props) => {
    const filters = useFiltersStore((state) => state.filters);
    const filtersWithAmount = useMemo(() => {
        return Object.entries(props.category.filters).reduce(
            (obj, [filterId, amount]) => ({
                ...obj,
                [filterId]: {
                    name: filters?.[filterId]?.name ?? "No Name",
                    amount,
                },
            }),
            {}
        );
    }, [props.category, filters]);

    return (
        <View
            title={props.category.name}
            filters={filtersWithAmount}
            color={props.category.color}
        />
    );
};

export default Category;
