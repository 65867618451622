import React from "react";
import styled from "styled-components";
import Title from "../../../components/Title";
import { Dictionary } from "../../../store";
import { ICategory } from "../../../store/categories";
import FilterCategory from "../filter-category";
import { motion } from "framer-motion";
import { useIsMobile } from "../../../ui";
import useUserSelectionStore from "../../../store/userSelection";
import Button from "../../../ui/components/Button";
import icons from "../../../ui/icons";
import Slider from "./Slider";
import { useIsMobileOrTablet } from "../../../utils/hooks";
import { getSorted } from "../../../utils";

const SFilterSection = styled(motion.div)`
    display: flex;
    flex-grow: 1;
    gap: 50px;
    flex-direction: column;
`;

const View: React.FC<{ categories: Dictionary<ICategory> }> = (props) => {
    const isMobileOrTablet = useIsMobileOrTablet();

    if (isMobileOrTablet) return <MobileView categories={props.categories} />;

    return (
        <SFilterSection
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
        >
            <Title>Let's help you find a career pathway</Title>
            {Object.values(props.categories).sort(getSorted).map((c) => (
                <FilterCategory key={c.id} category={c} />
            ))}
        </SFilterSection>
    );
};

const SMobileFilterSection = styled(motion.div)`
    display: flex;
    flex-grow: 1;
    gap: 16px;
    flex-direction: column;

    ${(p) => p.theme.tabletQuery} {
        gap: 32px;
    }
`;

const MobileView: React.FC<{ categories: Dictionary<ICategory> }> = (props) => {
    const isMobile = useIsMobile();
    const [startedSelection, startSelection] = useUserSelectionStore(
        (state) => [state.mobileStartedSelection, state.startMobileSelection]
    );

    return (
        <SMobileFilterSection
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
        >
            {!startedSelection && (
                <Title>Let's help you find a career pathway</Title>
            )}
            {!startedSelection && (
                <Button
                    icon={icons.frontArrow}
                    iconRight
                    style={{ width: isMobile ? "80%" : "50%", margin: "auto" }}
                    onClick={() => startSelection()}
                >
                    Start Quiz
                </Button>
            )}

            {startedSelection && (
                <Slider categories={Object.values(props.categories).sort(getSorted)} />
            )}
        </SMobileFilterSection>
    );
};

export default View;
