import React, { useState, useEffect, useMemo } from "react";
import View from "./view";
import { ICareerPath } from "../../../store/careerPaths";
import { useCareerCategories } from "../../../store";
import useGlobalStore from "../../../store/global";

const RightPanel: React.FC<{ career: ICareerPath }> = (props) => {
    const categories = useCareerCategories(props.career);
    const [callToAction, description] = useGlobalStore(state => [state.global?.texts.callToAction, state.global?.texts.description])

    const callToActionText = useMemo(() => props.career?.callToAction ?? callToAction ?? "Call To Action", [callToAction, props.career])
    const descriptionText = useMemo(() => props.career.description ?? description, [description, props.career])

    return <View career={props.career} categories={categories} callToAction={callToActionText} description={descriptionText}/>;
};

export default RightPanel;
