import styled from "styled-components";
import { MotionProps, motion } from "framer-motion";
import { IThemeColors } from "../theme";

interface ISVGProps extends MotionProps {
    image: string;
    contain?: boolean;
    color?: keyof IThemeColors;
}

const SVG = styled(motion.div)<ISVGProps>`
    mask-image: url(${(p) => p.image});
    mask-size: ${(p) => (p.contain ? "contain" : "cover")};
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: ${(p) => p.theme.colors?.[p.color ?? "white"]};
    height: 100%;
`;

export default SVG;
