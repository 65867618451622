import { useEffect, useMemo } from "react";
import useCareerPathStore, { ICareerPath } from "./careerPaths";
import { read } from "../firebase/database";
import useFiltersStore, { IFilter } from "./filters";
import useCategoriesStore, { ICategory } from "./categories";
import { IThemeColors } from "../ui/theme";
import useGlobalStore from "./global";

export interface IBase {
    id: string;
    name: string;
    order?: number;
}

export interface Dictionary<T> {
    [key: string]: T;
}

export function useInitStores() {
    const [setCareerPaths, careerPathsDatabase] = useCareerPathStore(
        (state) => [state.setCareerPaths, state.database]
    );

    const [setFilters, filtersDatabase] = useFiltersStore((state) => [
        state.setFilters,
        state.database,
    ]);

    const [setCategories, categorriesDatabase] = useCategoriesStore((state) => [
        state.setCategories,
        state.database,
    ]);

    const [setGlobal, globalDatabase] = useGlobalStore(state => [
        state.setGlobal, state.database
    ])

    // Just init states on awake.
    useEffect(() => {
        // Reads career paths from database and sets zustand state.
        read(careerPathsDatabase).then(setCareerPaths);
        read(filtersDatabase).then(setFilters);
        read(categorriesDatabase).then(setCategories);
        read(globalDatabase).then(setGlobal);
    }, []);
}

export function useCategoryFilters(category: ICategory): Dictionary<IFilter> {
    const filters = useFiltersStore((state) => state.filters);

    const categories = useMemo(() => {
        return Object.values(category.filters).reduce(
            (obj, filterId) => ({
                ...obj,
                [filterId]: filters[filterId],
            }),
            {}
        );
    }, [filters]);

    return categories;
}

export function useCareerCategories(career: ICareerPath) {
    const categories = useCategoriesStore((state) => state.categories);

    const careerCategories = useMemo(() => {
        const careerCategories: Dictionary<{
            id: string;
            name: string;
            filters: Dictionary<number>;
            order?: number;
            color: keyof IThemeColors;
        }> = {};

        Object.entries(career.filters ?? {}).forEach(([filterId, amount]) => {
            Object.values(categories).forEach((category) => {
                if (filterId in category.filters) {
                    if (!(category.id in careerCategories))
                        careerCategories[category.id] = {
                            id: category.id,
                            name: category.name,
                            color: category.color,
                            order: category.order,
                            filters: {},
                        };

                    careerCategories[category.id].filters[filterId] = amount;
                }
            });
        });
        return careerCategories;
    }, [categories, career]);

    return careerCategories;
}
