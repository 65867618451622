import { motion } from "framer-motion";
import styled from "styled-components";
import { IThemeColors } from "../ui/theme";

export const SDots = styled(motion.div)`
    display: flex;
    gap: 4px;
    width: min-content;
`;

export const SDot = styled(motion.div)<{
    active: boolean;
    color: keyof IThemeColors;
}>`
    width: 8px;
    height: 8px;
    border-radius: 50%;

    background-color: ${(p) =>
        p.active ? p.theme.colors[p.color] : p.theme.colors.warmGrey};
`;
