import React, { useEffect, useMemo } from "react";
import View from "./view";
import useCareerPathStore, { ICareerPath } from "../../store/careerPaths";
import { getParam } from "../../router";
import { params } from "../../router/config";
import { onCareerSelected } from "../../store/userSelection";

const CareersDetails = () => {
    const careerPaths = useCareerPathStore((state) => state.careerPaths);
    const careerPath = useMemo(() => {
        return careerPaths[getParam(params.career)];
    }, [careerPaths]);

    if (!careerPath) return null;

    return <View career={careerPath} />;
};

export default CareersDetails;
