import { ICareerPath } from "../store/careerPaths";
import { ICategory } from "../store/categories";
import { IFilter } from "../store/filters";
import {
    onCareerSelected,
    onFilterSelected,
    onInfoButtonClicked,
    onVisitMyWOrkplaceClicked,
} from "../store/userSelection";
import { insightsLog } from "../submodules/insights";
import React from "react";

const AnalyticsConnection = React.createContext({});

export const AnalyticsConnectionContext: React.FC<React.PropsWithChildren> = (
    props
) => {
    React.useEffect(() => {
        // App Open and Closed events.
        appOpen();
        window.addEventListener("beforeunload", appClosed);

        onFilterSelected.on(({ filter, category, selected }) => {
            if (selected) filterSelected(filter, category);
            else filterDeSelected(filter, category);
        });

        onCareerSelected.on(({ career }) => careerSelected(career));
        onVisitMyWOrkplaceClicked.on(({ career }) =>
            visitMyWorplaceClick(career)
        );
        onInfoButtonClicked.on(() => infoButtonClick());

        return () => {
            window.removeEventListener("beforeunload", appClosed);
            onFilterSelected.removeAllListeners();
            onCareerSelected.removeAllListeners();
            onVisitMyWOrkplaceClicked.removeAllListeners();
            onInfoButtonClicked.removeAllListeners();
        };
    }, []);

    return (
        <AnalyticsConnection.Provider value={{}}>
            {props.children}
        </AnalyticsConnection.Provider>
    );
};

function insightsLogWithConfiguration(eventName: string, data?: any): void {
    const config = {
        baseBody: {
            st_aid: "Wine Australia Career Matrix", // The application identifier.
            st_eid: "Wine Australia Career Matrix", // The experience identifier.
        },
        appKey: "v1.TEBMCHRDOVVRPTOFXVI",
    };
    insightsLog(config, eventName, data);
}

function appOpen(): void {
    insightsLogWithConfiguration("app open");
}

function appClosed(): void {
    insightsLogWithConfiguration("app closed");
}

function filterSelected(filter: IFilter, category: ICategory): void {
    insightsLogWithConfiguration("Filter Selected", {
        itl_na: category.name,
        it_na: filter.name,
    });
}

function filterDeSelected(filter: IFilter, category: ICategory): void {
    insightsLogWithConfiguration("Filter Deselected", {
        itl_na: category.name,
        it_na: filter.name,
    });
}

function careerSelected(career: ICareerPath): void {
    insightsLogWithConfiguration("Career Selected", { it_na: career.name });
}

function visitMyWorplaceClick(career: ICareerPath): void {
    insightsLogWithConfiguration("Visit my Workplace click", {
        it_na: career.name,
    });
}

function infoButtonClick(): void {
    insightsLogWithConfiguration("Info Button Selected");
}
