import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Text from "../ui/components/Text";
import { SDot, SDots } from "./Dots";

const SLoading = styled.div`
    display: flex;
    gap: 16px;
    width: min-content;
    align-items: center;
`;

const Loading = () => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => setValue((p) => ++p % 10), 100);
        return () => clearInterval(interval);
    }, []);

    return (
        <SLoading>
            <Text style={{ flexGrow: 1 }} size="H4" color="warmGrey" oneline>
                Loading
            </Text>
            <SDots>
                {new Array(10).fill(0).map((v, i) => (
                    <SDot key={i} active={i < value} color="magenta" />
                ))}
            </SDots>
        </SLoading>
    );
};

export default Loading;
