import React from "react";
import RouterProvider from "./router";
import { useInitStores } from "./store";
import { AnalyticsConnectionContext } from "./connections/analytics";

function App() {
    useInitStores(); // Read from firebase and set local states.

    return (
        <>
            <AnalyticsConnectionContext />

            <RouterProvider />
        </>
    );
}

export default App;
