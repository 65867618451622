import React from "react";
import styled from "styled-components";
import Text from "../ui/components/Text";

const STitle = styled.div<IProps>`
    padding-bottom: ${(p) => (p.small ? "7px" : "13px")};
    border-bottom: 0.5px solid ${(p) => p.theme.colors.warmGrey};
    height: min-content;
    width: 100%;

    display: flex;
`;

interface IProps extends React.PropsWithChildren {
    small?: boolean;
    actionText?: string;
    onClick?: () => void;
}

const Title: React.FC<IProps> = (props) => {
    return (
        <STitle {...props}>
            <Text
                oneline
                font="poppins"
                color="black"
                size={props.small ? "H4" : "H3"}
                style={{ flexGrow: 1 }}
            >
                {props.children}
            </Text>

            {props.actionText && (
                <Text
                    oneline
                    onClick={() => props.onClick && props.onClick()}
                    color="deepGreen"
                >
                    {props.actionText}
                </Text>
            )}
        </STitle>
    );
};

export default Title;
