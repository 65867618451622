import React from "react";
import View from "./view";
import useCategoriesStore from "../../../store/categories";

const FilterSection = () => {
    const categories = useCategoriesStore((state) => state.categories);

    return <View categories={categories} />;
};

export default FilterSection;
