import React from "react";
import { RouteObject } from "react-router-dom";

import Careers from "../pages/careers";
import CareersDetails from "../pages/career-details";

// Configure App routes here.
export const enum routeNames {
    careers = "/",
    careerDetails = "career-details",
}

export const enum params {
    career = "career",
}

const routes: RouteObject[] = [
    {
        path: routeNames.careers,
        element: <Careers />,
    },
    {
        path: routeNames.careerDetails,
        element: <CareersDetails />,
    },
];

export default routes;
