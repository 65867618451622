import { create } from "zustand";
import { Dictionary } from ".";
import { IFilter } from "./filters";
import { ICareerPath } from "./careerPaths";
import { Action } from "../utils";
import { ICategory } from "./categories";

export const onFilterSelected = new Action<{
    filter: IFilter;
    category: ICategory;
    selected: boolean;
}>();

export const onCareerSelected = new Action<{
    career: ICareerPath;
}>();

export const onVisitMyWOrkplaceClicked = new Action<{
    career: ICareerPath;
}>();

export const onInfoButtonClicked = new Action();

interface IUserSelection {
    selectedFilters: Dictionary<IFilter>;
    toggleFilter: (filter: IFilter, remove?: boolean) => void;

    activeCareerPaths: Dictionary<ICareerPath>;
    setCareerPathState: (career: ICareerPath, active: boolean) => void;

    mobileStartedSelection: boolean;
    startMobileSelection: () => void;
}

const useUserSelectionStore = create<IUserSelection>()((set) => ({
    selectedFilters: {},
    toggleFilter: (filter, remove) =>
        set((state) => {
            const selectedFilters = { ...state.selectedFilters };

            if (remove || filter.id in selectedFilters)
                delete selectedFilters[filter.id];
            else selectedFilters[filter.id] = filter;

            return { selectedFilters };
        }),

    activeCareerPaths: {},
    setCareerPathState: (career, active) =>
        set((state) => {
            const activeCareerPaths = { ...state.activeCareerPaths };

            if (active && !activeCareerPaths[career.id])
                activeCareerPaths[career.id] = career;
            else if (!active && activeCareerPaths[career.id])
                delete activeCareerPaths[career.id];

            return { activeCareerPaths };
        }),

    mobileStartedSelection: false,
    startMobileSelection: () => set({ mobileStartedSelection: true }),
}));

export default useUserSelectionStore;
