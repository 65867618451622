import React from "react";
import styled from "styled-components";
import Title from "../../../components/Title";
import { Dictionary } from "../../../store";
import { IFilter } from "../../../store/filters";
import Filter from "../filter";
import { ICategory } from "../../../store/categories";

const SFilterCategory = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const SGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(5, min-content);
    grid-gap: 11px;

    ${(p) => p.theme.tabletQuery} {
        grid-template-columns: repeat(4, 1fr);
        width: 80%;
        margin: auto;
    }

    ${(p) => p.theme.mobileQuery} {
        width: 100%;
        grid-gap: 8px;
        grid-template-columns: repeat(4, 1fr);
    }
`;

const View: React.FC<{
    title: string;
    filters: Dictionary<IFilter>;
    showClearSelection: boolean;
    clearSelectionHandler: () => void;
    category: ICategory;
}> = (props) => {
    return (
        <SFilterCategory>
            <Title
                actionText={
                    props.showClearSelection ? "Clear Selection" : undefined
                }
                onClick={() => props.clearSelectionHandler()}
            >
                {props.title}
            </Title>
            <SGrid>
                {Object.values(props.filters).map((f) => (
                    <Filter key={f.id} filter={f} category={props.category} />
                ))}
            </SGrid>
        </SFilterCategory>
    );
};

export default View;
