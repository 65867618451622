import React, { useState, useEffect, useMemo } from "react";
import View from "./view";
import useCareerPathStore, { ICareerPath } from "../../../store/careerPaths";
import { Dictionary } from "../../../store";
import { getSorted } from "../../../utils";
import useGlobalStore from "../../../store/global";

const CareerPathCatalog = () => {
    const careerPaths = useCareerPathStore((state) => state.careerPaths);
    const [deactivatedStates, setDeactivatedStates] = useState<
        Dictionary<boolean>
    >({});
    const [description] = useGlobalStore((state) => [
        state.global?.texts.description,
    ]);
    const items = useMemo(() => {
        return Object.values(careerPaths)
            .sort((a, b) => getSorted(a, b))
            .reduce(
                (obj, career) => ({
                    ...obj,
                    [career.id]: {
                        career,
                        deactivated: deactivatedStates[career.id],
                    },
                }),
                {}
            );
    }, [deactivatedStates]);

    useEffect(() => {
        const newDeactivatedStates = Object.values(careerPaths)
            .sort((a, b) => getSorted(a, b))
            .reduce(
                (obj, career) => ({
                    ...obj,
                    [career.id]: true,
                }),
                {}
            );

        setDeactivatedStates(newDeactivatedStates);

        setTimeout(() => {
            Object.keys(newDeactivatedStates).forEach((careerId, i) => {
                setTimeout(() => {
                    setDeactivatedStates((p) => ({
                        ...p,
                        [careerId]: false,
                    }));
                }, i * 50);
            });
        }, 1000);
    }, [careerPaths]);

    return <View items={items} description={description} />;
};

export default CareerPathCatalog;
