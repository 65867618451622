import { useState, useEffect, useContext } from "react";

export function useAwakeBool() {
    const [state, set] = useState(false);
    useEffect(() => {
        if (!state) set(true);
    }, [state]);
    return state;
}

export function useOutsideAlerter(ref: any, callback: () => void) {
    useEffect(() => {
        // Alert if clicked on outside of element
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState<boolean>();

    function handleResize() {
        const { innerWidth: width } = window;
        setIsMobile(width <= 480);
    }

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return isMobile;
};

export const useIsTablet = () => {
    const [isTablet, setIsTablet] = useState<boolean>();

    function handleResize() {
        const { innerWidth: width } = window;
        setIsTablet(width <= 1300);
    }

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return isTablet;
};

export const useIsMobileOrTablet = () => {
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();

    return isMobile || isTablet;
};

export function usePreloadImages(images: string[]) {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const imageObjects: HTMLImageElement[] = [];
        let loadedCount = 0;

        const handleImageLoad = () => {
            loadedCount++;
            if (loadedCount === images.length) setLoaded(true);
        };

        images.forEach((imageUrl) => {
            const img = new Image();
            img.onload = handleImageLoad;
            img.src = imageUrl;
            imageObjects.push(img);
        });

        return () => {
            imageObjects.forEach((img) => (img.onload = null));
        };
    }, [images]);

    return loaded;
}
