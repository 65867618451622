import "styled-components";
import { DefaultTheme } from "styled-components";
import deepcopy from "deepcopy";

export interface IThemeFontSizes {
    H1: string;
    H2: string;
    H3: string;
    H4: string;
    H5: string;
}

export interface IThemeColors {
    burntOrange: string;
    yellow: string;
    deepGreen: string;
    paleBlue: string;
    white: string;
    black: string;

    magenta: string;
    red: string;
    paleGreen: string;
    teal: string;
    warmGrey: string;
}

declare module "styled-components" {
    export interface DefaultTheme {
        colors: IThemeColors;
        fontSize: IThemeFontSizes;
        tabletQuery: string;
        mobileQuery: string;
        desktopQuery: string;
    }
}

const Theme: DefaultTheme = {
    colors: {
        burntOrange: "#F37032",
        yellow: "#fff78f",
        deepGreen: "#008466",
        paleBlue: "#9fdbed",
        white: "#ffffff",
        black: "#000000",
        magenta: "#c55d9d",
        red: "#c4153b",
        paleGreen: "#c5de92",
        teal: "#58c5c7",
        warmGrey: "#c0b9a9",
    },
    fontSize: {
        H1: "56px",
        H2: "20px",
        H3: "18px",
        H4: "15px",
        H5: "12px",
    },
    tabletQuery: "@media only screen and (max-width: 1300px)",
    mobileQuery: "@media only screen and (max-width: 480px)",
    desktopQuery: "@media only screen and (min-width: 1200px)",
};

export const MobileTheme = deepcopy(Theme);

// Mobile Configuration.
{
    MobileTheme.fontSize.H1 = "32px";
    MobileTheme.fontSize.H2 = "16px";
    MobileTheme.fontSize.H3 = "15px";
    MobileTheme.fontSize.H4 = "10px";
    MobileTheme.fontSize.H5 = "9px";
}

export const TabletTheme = deepcopy(Theme);

// Table Configuration.
{
}

export default Theme;
