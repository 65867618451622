import React from "react";
import styled, { css } from "styled-components";
import Text from "../../../ui/components/Text";

const SFilter = styled.div<IProps>`
    height: 42px;
    width: 108px;
    background-color: ${(p) => p.theme.colors.warmGrey};
    padding: 0 11px;
    cursor: pointer;
    display: flex;
    align-items: center;

    ${(p) =>
        p.active &&
        css`
            background-color: ${(p) => p.theme.colors.deepGreen};
        `}

    transition: .3s;

    :hover {
        background-color: ${(p) => p.theme.colors.deepGreen};
        transform: scale(1.1);
    }

    ${(p) =>
        p.disabled &&
        css`
            pointer-events: none;
            cursor: initial;
            opacity: 0.5;
        `}

    ${(p) => p.theme.tabletQuery} {
        width: auto;
    }

    ${(p) => p.theme.mobileQuery} {
        width: auto;
    }
`;

interface IProps {
    label: string;
    active?: boolean;
    disabled?: boolean;
    onClick: () => void;
}

const View: React.FC<IProps> = (props) => {
    return (
        <SFilter {...props} onClick={() => props.onClick()}>
            <Text color="white" size="H5">
                {props.label}
            </Text>
        </SFilter>
    );
};

export default View;
