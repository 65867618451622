import React from "react";
import {
    createBrowserRouter,
    RouterProvider as Provider,
} from "react-router-dom";
import routes, { params } from "./config";

const router = createBrowserRouter(routes);

const RouterProvider: React.FC = (props) => {
    return <Provider router={router} />;
};

export function getParam(param: params): string {
    try {
        return new URLSearchParams(window.location.search).get(param) ?? "";
    } catch {
        return "";
    }
}

export default RouterProvider;
