import React from "react";
import View from "./view";
import useCareerPathStore from "../../store/careerPaths";
import useFiltersStore from "../../store/filters";
import { usePreloadImages } from "../../utils/hooks";

const Careers = () => {
    const [careerPathsLoading, careerPaths] = useCareerPathStore((state) => [
        state.loading,
        state.careerPaths,
    ]);
    const filterLoading = useFiltersStore((state) => state.loading);

    const iconsLoaded = usePreloadImages(
        Object.values(careerPaths ?? {}).map((career) => career.icon)
    );

    const isLoading = careerPathsLoading || filterLoading || !iconsLoaded;

    return <View loading={isLoading} />;
};

export default Careers;
