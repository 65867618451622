import React, { useMemo } from "react";
import View from "./view";
import { ICategory } from "../../../store/categories";
import { useCategoryFilters } from "../../../store";
import useUserSelectionStore from "../../../store/userSelection";

const FilterCategory: React.FC<{ category: ICategory }> = (props) => {
    const filters = useCategoryFilters(props.category);
    const [selectedFilters, toggleFilter] = useUserSelectionStore((state) => [
        state.selectedFilters,
        state.toggleFilter,
    ]);

    const showClearSelection = useMemo(() => {
        let show = false;
        Object.keys(filters).forEach((filterId) => {
            if (filterId in selectedFilters) show = true;
        });
        return show;
    }, [selectedFilters, filters]);

    const clearSelectionHandler = () => {
        Object.values(filters).forEach((filter) => {
            if (filter.id in selectedFilters) toggleFilter(filter, true);
        });
    };

    return (
        <View
            title={props.category.name}
            category={props.category}
            filters={filters}
            showClearSelection={showClearSelection}
            clearSelectionHandler={() => clearSelectionHandler()}
        />
    );
};

export default FilterCategory;
